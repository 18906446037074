<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="姓名" name="user_realname"></c-form-item>
					<c-form-item type="select" label="所属部门" name="yzdw_organization_id" options="ORGANIZATION"></c-form-item>
				</c-form>
			</m-search>
			
			<m-operate>
				<c-button icon="add" @click="editHandle()">创建子账号</c-button>
			</m-operate>
			
			<c-table ref="table">
				<c-table-column
					label="姓名"
					name="user_realname"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="手机号码"
					name="phone"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="所属部门"
					name="yzdw_organization_name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="浙政钉用户ID"
					name="zzd_userid"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="是否管理员"
					width="120"
				>
					<template v-slot="{data}">{{data.yzdw_is_manage == 2 ? '是' : '否'}}</template>
				</c-table-column>
				
				<c-table-column
					label="是否启用"
					width="120"
				>
					<template v-slot="{data}">
						<c-switch v-if="userInfo.id != data.id" :value="data.state == 2" text="启用,禁用" @change="stateHandle(data, arguments[0], arguments[1], arguments[2])"></c-switch>
						<span v-else>{{data.state == 2 ? '启用' : '禁用'}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="140"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button @click="editHandle(data)">编辑</c-table-button>
						<c-table-button @click="deleteHandle(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		
		<c-dialog ref="accountEdit" width="600" button-name="确认并提交" @resolve="editSubmitHandle">
			<account-edit ref="accountEditForm"></account-edit>
		</c-dialog>
	</page>
</template>

<script>
	import accountEdit from './account_edit_form.vue'
	import {mapState} from 'vuex'
	
	export default {
		components: {
			accountEdit
		},
		
		computed: {
			...mapState(['userInfo'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				this.$refs.table.load({
					url: '/yzdw/user/list'
				});
			},
			
			editHandle(data) {
				this._edit = !!data;
				if (data) {
					this.$refs.accountEditForm.edit(data.id);
				} else {
					this.$refs.accountEditForm.add();
				}
				this.$refs.accountEdit.open({
					title: data ? '编辑子账号' : '创建子账号'
				});
			},
			
			editSubmitHandle(stop, next) {
				stop();
				
				this.$refs.accountEditForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
					this.$refs.table.update(this._edit ? 0 : 1);
				});
			},
			
			deleteHandle(data) {
				this.$confirm({
					text: '确定要删除此子账号吗？',
					resolve: () => {
						this.request({
							url: '/yzdw/user/delete',
							data: {
								user_id: data.id
							},
							loading: true,
							success: () => {
								this.$message({
									text: '删除成功'
								});
								this.$refs.table.update();
							}
						});
					}
				});
			},
			
			stateHandle(data, state, stop, next) {
				stop();
				this.request({
					url: '/yzdw/user/updateState',
					data: {
						user_id: data.id,
						state: state ? 2: 1
					},
					success: next
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>