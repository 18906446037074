<template>
	<c-form ref="form">
		<c-form-item type="hidden" name="user_id"></c-form-item>
		<c-form-item type="input" label="姓名" name="user_realname" required></c-form-item>
		<c-form-item type="input" label="手机号码" name="phone" required></c-form-item>
		<c-form-item v-model="administrator" type="radio" label="是否管理员" name="yzdw_is_manage" required :options="administratorOption"></c-form-item>
		<c-form-item v-show="administrator == 1" type="select" label="所属部门" name="yzdw_organization_id" required options="ORGANIZATION"></c-form-item>
		<c-form-item type="input" label="浙政钉用户ID" name="zzd_userid"></c-form-item>
		<c-form-item v-show="userInfo.id != id" v-model="state" type="radio" label="是否启用" name="state" required :options="stateOption"></c-form-item>
	</c-form>
</template>

<script>
	import {mapState} from 'vuex'
	
	export default {
		data() {
			return {
				id: '',
				administrator: 1,
				administratorOption: [{
					name: '否',
					value: 1
				}, {
					name: '是',
					value: 2
				}],
				state: 2,
				stateOption: [{
					name: '启用',
					value: 2
				}, {
					name: '禁用',
					value: 1
				}]
			}
		},
		
		computed: {
			...mapState(['userInfo'])
		},
		
		methods: {
			add() {
				this._edit = false;
				this.$refs.form.clear();
				this.$refs.form.set({
					yzdw_is_manage: 1,
					state: 2
				});
			},
			
			edit(id) {
				this._edit = true;
				this.id = id;
				this.request({
					url: '/yzdw/user/detail',
					data: {
						user_id: id
					},
					success: data => {
						data.yzdw_organization_id = data.yzdw_organization_id || 0;
						this.$refs.form.clear();
						this.$refs.form.set({
							user_id: data.id
						});
						this.$refs.form.set(data);
					}
				});
			},
			
			submit() {
				return new Promise(resolve => {
					this.$refs.form.submit({
						url: this._edit ? '/yzdw/user/edit' : '/yzdw/user/add',
						
						rule: {
							user_realname: '请填写姓名',
							phone: '请填写手机号码',
							yzdw_organization_id: [{
								type: 'empty',
								word: '请选择所属部门',
								skip: data => {
									return data.yzdw_is_manage == 2;
								}
							}]
						},
						
						success: resolve
					});
				})
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>