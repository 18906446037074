import { render, staticRenderFns } from "./account_list_pc.vue?vue&type=template&id=dfd6a548&scoped=true"
import script from "./account_list_pc.vue?vue&type=script&lang=js"
export * from "./account_list_pc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfd6a548",
  null
  
)

export default component.exports